import { Message, User } from '@api';
import { ReservationConversation } from './conversations.models';

export namespace ConversationsActions {
  export class FetchAll {
    static readonly type = '[Conversation] Fetch All';
    constructor(public conversations: ReservationConversation[]) {}
  }

  export class FetchMessages {
    static readonly type = '[Conversation] Fetch Messages';
    constructor(public reservationId: string) {}
  }

  export class AddMessage {
    static readonly type = '[Conversation] Add Message';
    constructor(public message: Message) {}
  }

  export class SendMessage {
    static readonly type = '[Conversation] Send Message';
    constructor(public payload: { reservationId: string; content: string; file?: string }) {}
  }

  export class OpenConversation {
    static readonly type = '[Conversation] Open Conversation';
    constructor(public reservationId: string, public fromUser?: User) {}
  }

  export class CloseConversation {
    static readonly type = '[Conversation] Close Conversation';
    constructor(public reservationId: string) {}
  }

  export class ToogleConversation {
    static readonly type = '[Conversation] Toogle Conversation';
    constructor(public reservationId: string) {}
  }
}
