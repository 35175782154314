import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@core/services/ui/alert.service';
import * as MyPublications from '@core/store/my-publications/my-publications.actions';
import { ScheduleWithAvailabilities, SchedulesApi } from '@api';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { delay } from '@shared/utils';
import { catchError, tap } from 'rxjs';
import { TriberActions } from '../triber/triber.actions';
import { ScheduleActions, ScheduleEntityEnum } from './schedules.actions';

export interface ScheduleStateModel {
  items: { [key: string]: ScheduleWithAvailabilities };
  loading: boolean;
  loaded: boolean;
}

@State<ScheduleStateModel>({
  name: 'schedules',
  defaults: {
    items: {},
    loaded: false,
    loading: false,
  },
})
@Injectable({ providedIn: 'root' })
export class ScheduleState {
  private readonly scheduleAPI = inject(SchedulesApi);
  private readonly myalerts = inject(AlertService);
  private readonly router = inject(Router);

  @Selector()
  static items(state: ScheduleStateModel): ScheduleWithAvailabilities[] {
    return Object.values(state.items);
  }

  @Selector()
  static loading(state: ScheduleStateModel): boolean {
    return state.loading;
  }
  @Selector()
  static loaded(state: ScheduleStateModel): boolean {
    return state.loaded;
  }

  static getScheduleById(id: string) {
    return createSelector([ScheduleState], (state: ScheduleStateModel) => state.items[id] ?? null);
  }

  @Action(ScheduleActions.FetchAll)
  fetch(ctx: StateContext<ScheduleStateModel>) {
    if (ctx.getState().loaded) {
      return;
    }
    ctx.patchState({ loading: true });
    return this.scheduleAPI.findMySchedules().pipe(
      tap(schedules => {
        const items = schedules.reduce((acc, item) => ({ ...acc, ...{ [item.uid]: item } }), {});
        ctx.patchState({ items, loaded: true, loading: false });
      })
    );
  }

  @Action(ScheduleActions.FetchOne)
  fetchOne(ctx: StateContext<ScheduleStateModel>, { uid }: ScheduleActions.FetchOne) {
    const items = ctx.getState().items;
    if (items[uid]) return;

    return this.scheduleAPI
      .findMySchedule({ uid })
      .pipe(tap(schedule => ctx.dispatch(new ScheduleActions.Add(schedule))));
  }

  @Action(ScheduleActions.Add)
  add(ctx: StateContext<ScheduleStateModel>, { schedule }: ScheduleActions.Add) {
    const items = ctx.getState().items;
    return ctx.patchState({ items: { ...items, ...{ [schedule.uid]: schedule } } });
  }

  @Action(ScheduleActions.Create)
  create(ctx: StateContext<ScheduleStateModel>, { createDto, assignTo }: ScheduleActions.Create) {
    return this.scheduleAPI.createSchedule(createDto).pipe(
      tap(schedule => {
        ctx.dispatch(new ScheduleActions.Add(schedule));
        if (!assignTo) return;

        const { uid, entity } = assignTo;

        switch (entity) {
          case ScheduleEntityEnum.RESUME:
            ctx.dispatch(new TriberActions.PatchResume({ scheduleId: schedule.uid }));
            break;
          case ScheduleEntityEnum.PUBLICATION:
            ctx.dispatch(new MyPublications.UpdateInDatabase(uid, { scheduleId: schedule.uid }));
            break;

          default:
            break;
        }
      })
    );
  }

  @Action(ScheduleActions.UpdateInDatabase)
  async updateDB(
    ctx: StateContext<ScheduleStateModel>,
    { updateDto }: ScheduleActions.UpdateInDatabase
  ) {
    ctx.patchState({ loading: true });
    await delay(1.5); // delay for loading indicator
    return this.scheduleAPI
      .updateSchedule(updateDto)
      .pipe(tap(schedule => ctx.dispatch(new ScheduleActions.Set(schedule))));
  }

  @Action(ScheduleActions.Set)
  updateOne(ctx: StateContext<ScheduleStateModel>, { schedule }: ScheduleActions.Set) {
    const items = ctx.getState().items;
    return ctx.patchState({
      items: { ...items, ...{ [schedule.uid]: { ...schedule } } },
      loading: false,
    });
  }

  @Action(ScheduleActions.Remove)
  delete(ctx: StateContext<ScheduleStateModel>, { uid }: ScheduleActions.Remove) {
    const items = ctx.getState().items;
    if (!items[uid]) return;
    ctx.patchState({ loading: true });
    return this.scheduleAPI.removeSchedule({ uid }).pipe(
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({ loading: false });
        // if (err.status === 409) {
        //   this.myalerts.error(err.error.message);
        // }
        throw err;
      }),
      tap(() => {
        delete items[uid];
        this.myalerts.success('El horario ha sido eliminado');
        return ctx.patchState({ items: { ...items }, loading: false });
      })
    );
  }
}
