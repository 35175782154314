import {
  CreateScheduleRequestParams,
  ScheduleWithAvailabilities,
  UpdateScheduleRequestParams,
} from '@api';

export enum ScheduleEntityEnum {
  RESUME = 'resume',
  PUBLICATION = 'publication',
}

export namespace ScheduleActions {
  export class FetchAll {
    static readonly type = '[Schedules] Fetch';
  }
  export class FetchOne {
    static readonly type = '[Schedules] Fetch One';
    constructor(public uid: string) {}
  }
  export class Add {
    static readonly type = '[Schedules] Add';
    constructor(public schedule: ScheduleWithAvailabilities) {}
  }

  export class Create {
    static readonly type = '[Schedules] Create';
    constructor(
      public createDto: CreateScheduleRequestParams,
      public assignTo?: {
        uid: string;
        entity: ScheduleEntityEnum;
      }
    ) {}
  }

  export class UpdateInDatabase {
    static readonly type = '[Schedules] Update';
    constructor(public updateDto: UpdateScheduleRequestParams) {}
  }

  export class Set {
    static readonly type = '[Schedules] Set';
    constructor(public schedule: ScheduleWithAvailabilities) {}
  }

  export class Remove {
    static readonly type = '[Schedules] Remove';
    constructor(public uid: string) {}
  }
}
